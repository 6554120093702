<template>
<div>
    <modal v-if="isOpen" v-model="isOpen" title="Client" @show="onShow" ref="modal" id="modal-client" size="lg" auto-focus>
        <div slot="title">{{ $t("client.client") }} {{ popupClient.client_ix }}</div>
        <h4><strong>{{ popupClient.client_firmname }}</strong></h4>
        <hr>
        <div v-if="ppClient" class="row">
            <div class="col-xs-12 col-md-6">
                <p>{{ $t("client.info") }} <strong>{{ $t("client.info2") }}</strong></p>
                <p>{{ $t("client.email") }}: {{ ppClient.email }}</p>
                <p v-if="popupClient.client_maxserwis=='1'">{{ $t("client.info3") }} <strong class="color-maxserwis">ma<span>X</span>serwis</strong></p>
            </div>
            <div class="col-xs-12 col-md-6">
                
            </div>
            <div class="col-xs-12 text-center">
                <p><a @click="openUrl">{{ $t("client.login") }}</a></p>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-xs-12 col-md-6">
                <h5>{{ $t("client.withoutPA") }}</h5>
                <p>{{ $t("client.email") }}: {{ popupClient.client_email }}</p>
            </div>
        </div>
        
        <div slot="footer">
            <btn @click="isOpen=false">{{ $t("default.close") }}</btn>
        </div>
    </modal>
</div>
</template>

<script>
//import _ from 'lodash'
import { mapGetters } from 'vuex'
import { AP_PROMOTIONS_AUTH_ADDRESS } from '../common/config'
import { CLOSE_POPUP_CLIENT } from '../store/mutations.type'
import _ from 'lodash'

export default {
    methods: {
      onShow() {
          // console.log('client')
      },
      openUrl() {
          window.open(this.loginLink,'_blank')
      }
    },
    computed: {
        ...mapGetters(['isPopupClientOpen', 'popupClient', 'ppClients']),
        isOpen: {
            get() {
                return this.isPopupClientOpen
            },
            set() {
                this.$store.commit(CLOSE_POPUP_CLIENT)
            }
        },
        loginLink() {
            return AP_PROMOTIONS_AUTH_ADDRESS + '?usertoken=' + this.ppClient.token + '&'
        },
        ppClient() {
           return  _.find(this.ppClients, ppClient => ppClient.ix == this.popupClient.client_ix)
        }
    }
}
</script>