<style>
  table.header--maxgearsky tr {font-size: 12px;}
  table.header--maxgearsky thead tr th:nth-child(1) { width: 60px; }
  table.header--maxgearsky thead tr th:nth-child(2) { width: 90px; }
  table.header--maxgearsky thead tr th:nth-child(3) { width: 90px; }
  table.header--maxgearsky thead tr th:nth-child(4) { width: 44px; }
  table.header--maxgearsky thead tr th:nth-child(5) { width: 110px; }
  table.header--maxgearsky thead tr th:nth-child(6) { width: 77px; }
  table.header--maxgearsky thead tr th:nth-child(7) { width: 66px; }
  table.header--maxgearsky thead tr th:nth-child(8) { width: 66px; }
  table.header--maxgearsky thead tr th:nth-child(9) { width: 66px; }
  table.header--maxgearsky thead tr th:nth-child(10) { width: 66px; }
  table.header--maxgearsky thead tr th:nth-child(11) { width: 66px; }
  table.header--maxgearsky thead tr th:nth-child(12) { width: 66px; }
</style>

<template>

    <main class="main2">

        <div class="container" v-if="maxgearsky && maxgearsky.length > 0">

            <div class="row">
                <div class="col-xs-12 col-md-5">
                    <input type="text" class="form-control matb20" v-model="search" :placeholder="$t('tables.search')" />
                </div>
                <div class="col-xs-12 col-md-7">
                    <div class="flexWrap">
                        <div @click="download2" class="abtn--c4" style="margin-right: 16px;cursor: pointer">{{ $t('default.download') }}</div>
                        <pagination v-model="page" :total-page="pages" size="md" align="right" boundary-links/>
                    </div>                
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <Table
                        class="rwdtable header--maxgearsky" style="width:100%"
                        :items="maxgearsky" 
                        :headers="headers" 
                        :fields="fields" 
                        :search="search"
                        :page.sync="page"
                        :itemsPerPage="itemsPerPage"
                        :pages.sync="pages"
                        @tr:clicked="OPEN_POPUP_CLIENT($event.client_ix)"
                    />

                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <pagination v-model="page" :total-page="pages" size="md" align="right" boundary-links/>
                </div>
            </div>


        </div>
        <div v-else-if="maxgearsky && maxgearsky.length === 0" class="container">
          <div class="row">
            <div class="col-xs-12 text-center" style="padding: 20px 0;">
              <h1>{{ $t('default.noData') }}</h1>
            </div>
          </div>
        </div>  
        <div v-else class="container">
          <div class="row">
            <div class="col-xs-12 text-center" style="padding: 20px 0;">
              <h1>{{ $t('default.loading') }}<br>{{ $t('default.loadingTime') }}</h1>
            </div>
          </div>
        </div>

    </main>

</template>


<script>
import { mapGetters, mapMutations } from 'vuex'
import Table from '../components/Table'
import { FETCH_MAXGEARSKY } from '../store/actions.type'
import { OPEN_POPUP_CLIENT } from '../store/mutations.type'
import exportFromJSON from 'export-from-json'
import xlsx from "json-as-xlsx"

export default {
    components: {
        Table,
    },
    data() {
        return {
            fields: [
              'in_rank',
              'client_ix',
              'amount',
              'basic',
              'b_percent',
              'max',
              'm_percent',
              's_percent',
              's_times',
            ],
            search: '',
            itemsPerPage: 30,
            page: 1,
            pages: 1,
        }
    },
    computed: {
        ...mapGetters(['maxgearsky']),
        headers() {
          return [
              this.$i18n.t("tables.ranking"),
              this.$i18n.t("tables.customerNumber"),
              this.$i18n.t("tables.purchaseAmount"),
              this.$i18n.t("tables.basic"),
              this.$i18n.t("tables.primarTarget"),
              this.$i18n.t("tables.premium"),
              this.$i18n.t("tables.premiumTarget"),
              this.$i18n.t("tables.bonusTarget"),
              this.$i18n.t("tables.bonusEarned")
            ]
        }
    },
    created() {
        this.$store.dispatch(FETCH_MAXGEARSKY)
    },
    methods: {
        ...mapMutations([OPEN_POPUP_CLIENT]),
        download() {
            const reduceItems = this.fields.reduce((accumulator, element, index) => {
            return {...accumulator, [element]: this.headers[index]};
            }, {});
            const data = this.maxgearsky
            const fields = reduceItems
            const fileName = `MAXGEAR_SKY_IS_THE_LIMIT`
            const exportType =  exportFromJSON.types.xls

            exportFromJSON({ data, fileName, fields, exportType })
        },
        download2() {
            let data = [
                {
                    sheet: '',
                    columns: [
                        { label: this.$i18n.t("tables.ranking"), value: "in_rank" }, 
                        { label: this.$i18n.t("tables.customerNumber"), value: "client_ix" }, 
                        // eslint-disable-next-line no-useless-escape
                        { label: this.$i18n.t("tables.purchaseAmount"), value: (row) => Number(row.amount), format: "#,##0.00\ [$zł-415]" }, 
                        // eslint-disable-next-line no-useless-escape
                        { label: this.$i18n.t("tables.basic"), value: (row) => Number(row.basic), format: "#,##0.00\ [$zł-415]" }, 
                        { label: this.$i18n.t("tables.primarTarget"), value: "b_percent" }, 
                        // eslint-disable-next-line no-useless-escape
                        { label: this.$i18n.t("tables.premium"), value: (row) => Number(row.max), format: "#,##0.00\ [$zł-415]" }, 
                        { label: this.$i18n.t("tables.premiumTarget"), value: "m_percent" }, 
                        { label: this.$i18n.t("tables.bonusTarget"), value: "s_percent" }, 
                        { label: this.$i18n.t("tables.bonusEarned"), value: "s_times" }, 
                    ],
                    content: this.maxgearsky
                }
            ]

            let settings = {
                fileName: `MAXGEAR_SKY_IS_THE_LIMIT`,
                extraLength: 3,
                writeMode: 'writeFile',
                writeOptions: {}, 
            }
            xlsx(data, settings)

        }          
    }
}
</script>


