export const SET_INIT = "SET_INIT";
export const SET_MY_INFO = "SET_MY_INFO";
export const SET_MY_BONUSES = "SET_MY_BONUSES";
export const SET_MY_BONUSES_DRG = "SET_MY_BONUSES_DRG";
export const OPEN_POPUP_STATUTE = "OPEN_POPUP_STATUTE";
export const CLOSE_POPUP_STATUTE = "CLOSE_POPUP_STATUTE";
export const OPEN_POPUP_ACCOUNT = "OPEN_POPUP_ACCOUNT";
export const CLOSE_POPUP_ACCOUNT = "CLOSE_POPUP_ACCOUNT";
export const OPEN_POPUP_CLIENT = "OPEN_POPUP_CLIENT";
export const CLOSE_POPUP_CLIENT = "CLOSE_POPUP_CLIENT";
export const OPEN_POPUP_PROMOTION_CLIENT = "OPEN_POPUP_PROMOTION_CLIENT";
export const CLOSE_POPUP_PROMOTION_CLIENT = "CLOSE_POPUP_PROMOTION_CLIENT";
export const SET_PROMOTION = "SET_PROMOTION";
export const SET_MY_CLIENTS = "SET_MY_CLIENTS";
export const SET_MY_CLIENTS_LOADING = "SET_MY_CLIENTS_LOADING";
export const SET_LANG = "SET_LANG";
export const SET_PP_CLIENTS = "SET_PP_CLIENTS";
export const SET_MAXGEARSKY = "SET_MAXGEARSKY";
export const SET_APEXPERT = "SET_APEXPERT";
export const SET_VIPTOTRIP = "SET_VIPTOTRIP";
export const SET_wlm = "SET_wlm";
export const SET_spn3 = "SET_spn3";
export const SET_lnmwarsztat = "SET_lnmwarsztat";
export const SET_lnmsklep = "SET_lnmsklep";
export const SAVE_PROMPT_EVENT = "SAVE_PROMPT_EVENT";
export const CLEAR_PROMPT_EVENT = "CLEAR_PROMPT_EVENT";
export const SET_IS_ONLINE = "SET_IS_ONLINE";
