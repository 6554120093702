export default {
  dev: false,
  init: undefined,
  myInfo: undefined,
  myBonuses: undefined,
  myBonusesDrg: undefined,
  myCompanyCurrent: undefined,
  logout:
    "https://filia.autopartner.com/simplesaml/module.php/core/as_logout.php?AuthId=default-sp&ReturnTo=https%3A%2F%2Ffilia.autopartner.com",
  isPopupStatuteOpen: false,
  popupStatuteText: "",
  lang: "pl",
  isPopupAccountOpen: false,
  isPopupClientOpen: false,
  isPopupPromotionClientOpen: false,
  popupClient: undefined,
  popupPromotionClient: undefined,
  promotion: undefined,
  myClients: undefined,
  ppClients: undefined,
  myClientsLoading: false,
  maxgearsky: undefined,
  apexpert: undefined,
  viptotrip: undefined,
  wlm: undefined,
  spn3: undefined,
  lnmwarsztat: undefined,
  lnmsklep: undefined,
  promptEvent: null,
  isOnline: true,
  app_promotions: [],
  app_promotions_archive: [],
  app_promotion: null,
  app_promotion_clients: null,
};
