import apiApPromotions from "@/api/ap-promotions";
import apiFilia from "@/api/api-filia";
import {
  SET_INIT,
  SET_MY_INFO,
  OPEN_POPUP_STATUTE,
  CLOSE_POPUP_STATUTE,
  OPEN_POPUP_ACCOUNT,
  CLOSE_POPUP_ACCOUNT,
  OPEN_POPUP_CLIENT,
  CLOSE_POPUP_CLIENT,
  SET_PROMOTION,
  SET_MY_CLIENTS,
  SET_lnmwarsztat,
  SET_lnmsklep,
  SET_MY_CLIENTS_LOADING,
  SET_LANG,
  SET_PP_CLIENTS,
  OPEN_POPUP_PROMOTION_CLIENT,
  CLOSE_POPUP_PROMOTION_CLIENT,
  SET_MAXGEARSKY,
  SET_APEXPERT,
  SET_VIPTOTRIP,
  SET_wlm,
  SET_spn3,
  SET_MY_BONUSES,
  SET_MY_BONUSES_DRG,
  SAVE_PROMPT_EVENT,
  CLEAR_PROMPT_EVENT,
  SET_IS_ONLINE,
} from "./mutations.type";
import Cookies from "js-cookie";

export default {
  [SET_INIT](state, init) {
    state.init = init;
    apiApPromotions.defaults.data = {
      token: state.init.token,
      lang: state.lang,
      myinfo1: state.myInfo,
      myinfo2: state.myInfo,
      myinfo3: state.myInfo,
    };
  },
  [SET_MY_INFO](state, myInfo) {
    state.myInfo = myInfo;
    state.myCompanyCurrent = myInfo.branch_company[0];

    var lang;
    switch (myInfo.branch_company[0]) {
      case "czk":
        lang = "cz";
        break;
      case "ap":
      default:
        lang = "pl";
        break;
    }
    state.lang = lang;
    apiApPromotions.defaults.data = {
      token: state.init.token,
      lang: lang,
      myinfo1: state.myInfo.branch_account_email,
      myinfo2:
        state.myInfo.branch_account_firstname +
        " " +
        state.myInfo.branch_account_lastname,
      myinfo3: state.myInfo.branch_account_name,
    };

    /*
      if( myInfo.branch_company.length>1 ) {
        state.isPopupAccountOpen = true
      }
      */
    apiFilia.defaults.data = {
      company: state.myCompanyCurrent,
    };
  },
  [SET_MY_BONUSES](state, myBonuses) {
    if (myBonuses.realisation.groupname) {
      myBonuses.realisation_branches.push({
        branch_position: Number(myBonuses.realisation.position),
        branch_ix: Number(state.myInfo.branch_ix),
        branch_name:
          state.myInfo.branch_account_name + " - ### TWOJA FILIA ###",
        branch_groupname: myBonuses.realisation.groupname,
        branch_percent: myBonuses.realisation.percent,
      });

      if (myBonuses.realisation_branches.length > 0) {
        var d = Math.pow(10, 2);
        for (var i = 0; i < myBonuses.realisation_branches.length; i++) {
          myBonuses.realisation_branches[i]["branch_ix"] = Number(
            myBonuses.realisation_branches[i]["branch_ix"]
          );
          myBonuses.realisation_branches[i]["branch_position"] =
            myBonuses.realisation_branches[i]["branch_position"] == "0"
              ? 9999
              : Number(myBonuses.realisation_branches[i]["branch_position"]);
          myBonuses.realisation_branches[i]["branch_percent"] = Number(
            (
              parseInt(
                myBonuses.realisation_branches[i]["branch_percent"].replace(
                  ",",
                  "."
                ) * d
              ) / d
            ).toFixed(2)
          );
          myBonuses.realisation_branches[i]["branch_percent2"] =
            myBonuses.realisation_branches[i]["branch_percent"] + " %";
        }
      }

      state.myBonuses = myBonuses;
    }
  },
  [SET_MY_BONUSES_DRG](state, myBonusesDrg) {
    if (myBonusesDrg.realisation_branches_drg.length > 0) {
      var d = Math.pow(10, 2);
      var dd = Math.pow(10, 0);
      for (var i = 0; i < myBonusesDrg.realisation_branches_drg.length; i++) {
        myBonusesDrg.realisation_branches_drg[i]["ix"] = Number(
          myBonusesDrg.realisation_branches_drg[i]["ix"]
        );
        myBonusesDrg.realisation_branches_drg[i]["position"] =
          myBonusesDrg.realisation_branches_drg[i]["position"] == "0"
            ? 9999
            : Number(myBonusesDrg.realisation_branches_drg[i]["position"]);
        myBonusesDrg.realisation_branches_drg[i]["groupname"] =
          "gr." + myBonusesDrg.realisation_branches_drg[i]["groupname"];
        myBonusesDrg.realisation_branches_drg[i]["amount"] = Number(
          (
            parseInt(
              myBonusesDrg.realisation_branches_drg[i]["amount"].replace(
                ",",
                "."
              ) * dd
            ) / dd
          ).toFixed(0)
        );
        myBonusesDrg.realisation_branches_drg[i]["target"] = Number(
          (
            parseInt(
              myBonusesDrg.realisation_branches_drg[i]["target"].replace(
                ",",
                "."
              ) * dd
            ) / dd
          ).toFixed(0)
        );
        myBonusesDrg.realisation_branches_drg[i]["individual_target"] = Number(
          (
            parseInt(
              myBonusesDrg.realisation_branches_drg[i][
                "individual_target"
              ].replace(",", ".") * dd
            ) / dd
          ).toFixed(0)
        );
        myBonusesDrg.realisation_branches_drg[i]["percent"] = Number(
          (
            parseInt(
              myBonusesDrg.realisation_branches_drg[i]["percent"].replace(
                ",",
                "."
              ) * d
            ) / d
          ).toFixed(2)
        );
        myBonusesDrg.realisation_branches_drg[i]["individual_percent"] = Number(
          (
            parseInt(
              myBonusesDrg.realisation_branches_drg[i][
                "individual_percent"
              ].replace(",", ".") * d
            ) / d
          ).toFixed(2)
        );
      }
    }

    state.myBonusesDrg = myBonusesDrg;
  },
  [OPEN_POPUP_STATUTE](state, text) {
    state.popupStatuteText = text;
    state.isPopupStatuteOpen = true;
  },
  [CLOSE_POPUP_STATUTE](state) {
    state.isPopupStatuteOpen = false;
  },
  [OPEN_POPUP_ACCOUNT](state) {
    state.isPopupAccountOpen = true;
  },
  [CLOSE_POPUP_ACCOUNT](state) {
    state.isPopupAccountOpen = false;
  },
  [OPEN_POPUP_CLIENT](state, ix) {
    state.isPopupClientOpen = true;
    state.popupClient = state.myClients.clients.find(
      (client) => client.client_ix == ix
    );
  },
  [CLOSE_POPUP_CLIENT](state) {
    state.isPopupClientOpen = false;
  },
  [OPEN_POPUP_PROMOTION_CLIENT](state, client) {
    state.isPopupPromotionClientOpen = true;
    state.popupPromotionClient = client;
  },
  [CLOSE_POPUP_PROMOTION_CLIENT](state) {
    state.isPopupPromotionClientOpen = false;
  },
  [SET_PROMOTION](state, promotion) {
    state.promotion = promotion;
  },
  ["SET_APP_PROMOTIONS_ARCHIVE"](state, promotions) {
    state.app_promotions_archive = promotions;
  },
  ["SET_APP_PROMOTIONS"](state, promotions) {
    state.app_promotions = promotions;
  },
  ["SET_APP_PROMOTION"](state, promotion) {
    state.app_promotion = promotion;
  },
  ["SET_APP_PROMOTION_CLIENTS"](state, clients) {
    state.app_promotion_clients = clients;
  },
  [SET_MY_CLIENTS](state, myClients) {
    state.myClients = myClients;
  },
  [SET_MY_CLIENTS_LOADING](state, myClientsLoading) {
    state.myClientsLoading = myClientsLoading;
  },
  [SET_LANG](state, lang) {
    state.lang = lang;
  },
  [SET_PP_CLIENTS](state, ppClients) {
    state.ppClients = ppClients;
  },
  [SET_MAXGEARSKY](state, maxgearsky) {
    state.maxgearsky = maxgearsky;
  },
  [SET_APEXPERT](state, apexpert) {
    state.apexpert = apexpert;
  },
  [SET_VIPTOTRIP](state, viptotrip) {
    state.viptotrip = viptotrip;
  },
  [SET_wlm](state, wlm) {
    state.wlm = wlm;
  },
  [SET_spn3](state, spn3) {
    state.spn3 = spn3;
  },
  [SET_lnmwarsztat](state, lnmwarsztat) {
    state.lnmwarsztat = lnmwarsztat;
  },
  [SET_lnmsklep](state, lnmsklep) {
    state.lnmsklep = lnmsklep;
  },
  [SET_IS_ONLINE](state, isOnline) {
    state.isOnline = isOnline;
  },
  [SAVE_PROMPT_EVENT](state, event) {
    state.promptEvent = event;
  },
  [CLEAR_PROMPT_EVENT](state) {
    state.promptEvent = null;
    Cookies.set("prompt-pwa", "true", { expires: 7 });
  },
};
