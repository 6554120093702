<style src="@/assets/css/bootstrap.min.css"></style>
<style src="@/assets/css/font-awesome.min.css"></style>
<style src="@/assets/css/xaareset.css"></style>
<style src="@/assets/css/ymfval.css"></style>
<style src="@/assets/css/xcustom.css"></style>

<template>
  <div id="app">
    <TheHeader />

    <div v-if="loading" class="main-loader-wrapper">
      <div class="main-loader">
        <p class="loading2">{{ $t("default.loadingData") }}</p>
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

    <router-view v-else name="view" />

    <TheFooter />

    <PopupStatute />
    <PopupAccount />
    <PopupClient />

    <pwa-notification />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import PopupStatute from "@/components/PopupStatute";
import PopupAccount from "@/components/PopupAccount";
import PopupClient from "@/components/PopupClient";
import TheFooter from "@/components/TheFooter";
import PwaNotification from "@/components/PwaNotification";

import {
  FETCH_INIT,
  FETCH_MY_CLIENTS,
  FETCH_PP_CLIENTS,
  FETCH_MY_INFO,
  FETCH_MY_BONUSES,
} from "./store/actions.type";

export default {
  components: {
    TheHeader,
    TheFooter,
    PopupStatute,
    PopupAccount,
    PopupClient,
    PwaNotification,
  },
  data() {
    return {
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch(FETCH_INIT);
    await this.$store.dispatch(FETCH_MY_INFO);
    this.$store.dispatch(FETCH_MY_BONUSES);
    // this.$store.dispatch(FETCH_MY_BONUSES_DRG)
    await this.$store.dispatch(FETCH_MY_CLIENTS);
    await this.$store.dispatch(FETCH_PP_CLIENTS);

    this.loading = false;
  },
};
</script>
